<template>
  <b-overlay
    :show="loading"
    rounded="sm"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
  >
    <b-card
      class="product-edit-wrapper"
      :title="`Region: ${
        productEdit.region_id ? productEdit.region_id.toUpperCase() : ''
      }`"
    >
      <validation-observer ref="editProductValidation">
        <!-- form -->
        <b-form class="mt-2">
          <b-row>
            <!-- Title -->
            <b-col md="6">
              <b-form-group
                label="Title"
                label-for="product-edit-title"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    id="product-edit-title"
                    v-model="productEdit.title"
                    @input.native="onChangeTitle"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Category -->
            <b-col md="6">
              <b-form-group
                label="Category"
                label-for="product-edit-category"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Category"
                  rules="required"
                >
                  <v-select
                    id="product-edit-category"
                    v-model="productEdit.category_ids"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="productCategory"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Slug -->
            <b-col md="6">
              <b-form-group
                label="Slug"
                label-for="product-edit-slug"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Slug"
                  rules="required"
                >
                  <b-form-input
                    id="product-edit-slug"
                    v-model="productEdit.slug"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Tags -->
            <b-col md="6">
              <b-form-group
                v-if="productTags.length > 0"
                label="Tags"
                label-for="tags-edit-vendor"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Tags"
                  rules="required"
                >
                  <v-select
                    id="region-edit-vendor"
                    v-model="productEdit.tag_ids"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="productTags"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Date time -->
            <b-col md="6">
              <b-form-group
                label="Date time"
                label-for="product-add-date-time"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Date time"
                  rules="required"
                >
                  <flat-pickr
                    v-model="productEdit.startDateTime"
                    class="form-control"
                    :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Duration -->
            <b-col md="6">
              <b-form-group
                label="Duration (minutes)"
                label-for="product-add-date-time"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Duration"
                  rules="required"
                >
                  <b-form-input
                    id="product-add-slug"
                    v-model="productEdit.duration"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Roles -->
            <b-col md="6">
              <b-form-group
                label="Role"
                label-for="product-add-role"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Role"
                  rules="required"
                >
                  <v-select
                    id="product-add-role"
                    v-model="productEdit.product_roles"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="productRole"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Business Challenges -->
            <b-col md="6">
              <b-form-group
                label="Business Challenges"
                label-for="product-add-business"
                class="mb-2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Business Challenges"
                  rules="required"
                >
                  <v-select
                    id="product-add-role"
                    v-model="productEdit.product_bussiness_challenges"
                    multiple
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="productBussiess"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- Speakers -->
            <b-col md="12">
              <b-row>
                <b-col
                  cols="11"
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                  "
                >
                  <b-form-group label="Speakers" label-for="product-speaker">
                    <validation-provider
                      #default="{ errors }"
                      name="Speaker"
                      rules="required"
                    >
                      <swiper
                        ref="speakerRef"
                        v-model="productEdit.speakers"
                        style="
                          border: solid 1px;
                          border-color: #b8c2cc;
                          border-radius: 1rem;
                          padding: 1rem;
                        "
                        class="swiper-multiple"
                        :options="swiperOptions"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :style="
                          !productEdit.speakers.length ? 'height: 16rem;' : ''
                        "
                      >
                        <template v-if="productEdit.speakers.length">
                          <swiper-slide
                            v-for="(data, indexSpeaker) in productEdit.speakers"
                            :key="indexSpeaker"
                          >
                            <b-col>
                              <b-row
                                style="
                                  border: dashed 2px;
                                  border-radius: 1rem;
                                  border-color: #db536a;
                                  cursor: pointer;
                                  height: 10rem;
                                  justify-content: center;
                                "
                              >
                                <b-col
                                  cols="10"
                                  @click="openSetImageSpeaker(indexSpeaker)"
                                >
                                  <div class="select-image">
                                    <span v-if="!data.image">Select image</span>
                                    <b-img
                                      v-if="data.image"
                                      fluid
                                      :src="data.image.link"
                                      style="height: 10rem; width: 20rem"
                                    />
                                  </div>
                                </b-col>
                                <b-col
                                  cols="2"
                                  class="back-sub-element"
                                  @click="removeElemenSpeaker(indexSpeaker)"
                                >
                                  <div class="sub-element">-</div>
                                </b-col>
                              </b-row>
                              <b-row class="mt-1">
                                <b-col md="6">
                                  <b-form-group
                                    label="Name"
                                    label-for="product-speaker-name"
                                    class="mb-2"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="Name"
                                      rules="required"
                                    >
                                      <b-form-input
                                        id="product-speaker-name"
                                        v-model="
                                          productEdit.speakers[indexSpeaker]
                                            .name
                                        "
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                                <b-col md="6">
                                  <b-form-group
                                    label="Role"
                                    label-for="product-speaker-role"
                                    class="mb-2"
                                  >
                                    <validation-provider
                                      #default="{ errors }"
                                      name="Role"
                                      rules="required"
                                    >
                                      <b-form-input
                                        id="product-speaker-role"
                                        v-model="
                                          productEdit.speakers[indexSpeaker]
                                            .role
                                        "
                                      />
                                      <small class="text-danger">{{
                                        errors[0]
                                      }}</small>
                                    </validation-provider>
                                  </b-form-group>
                                </b-col>
                              </b-row>
                            </b-col>
                          </swiper-slide>
                        </template>
                        <template v-else>
                          <div
                            style="
                              height: 100%;
                              justify-content: center;
                              align-items: center;
                              width: 100%;
                              display: flex;
                            "
                          >
                            No item added yet!
                          </div>
                        </template>
                        <!-- Add Arrows -->
                        <div
                          slot="button-next"
                          class="swiper-button-next"
                          @click="nextSlide()"
                        />
                        <div
                          slot="button-prev"
                          class="swiper-button-prev"
                          @click="previousSlide()"
                        />
                      </swiper>

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="1"
                  style="padding: 1rem"
                  @click="appendElemenSpeaker()"
                >
                  <div class="add-element">+</div>
                </b-col>
              </b-row>
            </b-col>
            <!-- description form -->
            <b-col md="12">
              <transition-group
                name="custom-classes"
                enter-active-class="animate__animated animate__fadeInDown"
                leave-active-class="animate__animated animate__fadeOutUp"
                :duration="300"
              >
                <b-card-body v-for="index in count" :key="index">
                  <!-- repeater from -->
                  <b-col cols="12">
                    <div class="border rounded p-2">
                      <div
                        v-if="arrayDescription.length > 1 && index !== 1"
                        class="d-flex flex-row-reverse py-50 px-25 col-sm-12"
                      >
                        <feather-icon
                          size="16"
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="removeItem(index - 1)"
                        />
                      </div>
                      <b-row>
                        <!-- Language -->
                        <b-col md="6">
                          <b-form-group
                            label="Language"
                            label-for="product-edit-region"
                            class="mb-2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Language"
                              rules="required"
                            >
                              <v-select
                                id="region-edit-region"
                                v-model="arrayDescription[index - 1].region_id"
                                :dir="
                                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                                "
                                :options="languageOptions"
                                :clearable="false"
                                :disabled="index === 1"
                                @input="
                                  (val) =>
                                    updateItemFormDescription(index - 1, val)
                                "
                                :selectable="
                                  (option) => {
                                    return !arrayDescription.find(
                                      (x) => x.region_id.value === option.value
                                    );
                                  }
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Title -->
                        <b-col md="6">
                          <b-form-group
                            label="Title"
                            label-for="product-edit-title"
                            class="mb-2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Title"
                              rules="required"
                            >
                              <b-form-input
                                id="product-edit-title"
                                v-model="arrayDescription[index - 1].title"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Summary -->
                        <b-col md="12">
                          <b-form-group
                            label="Summary"
                            label-for="product-add-Summary"
                            class="mb-2"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="Summary"
                              rules="required"
                            >
                              <b-form-input
                                id="product-add-Summary"
                                v-model="arrayDescription[index - 1].summary"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <!-- Event Overview -->
                      <b-col cols="12">
                        <b-form-group
                          label="Event Overview"
                          label-for="product-content"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Event Overview"
                            rules="required"
                          >
                            <quill-editor
                              id="product-content"
                              v-model="arrayDescription[index - 1].description"
                              class="editor-form"
                              :options="snowOption"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                  </b-col>
                </b-card-body>
              </transition-group></b-col
            >

            <b-col cols="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="ml-1"
                size="sm"
                variant="primary"
                @click="addNewItemInItemFormDescription"
              >
                Add description form
              </b-button>
            </b-col>

            <b-card-body class="mb-2 pb-2 w-100">
              <b-col cols="12" class="mb-2">
                <div class="border rounded p-2 pb-0">
                  <h4 class="mb-1">Featured Image</h4>
                  <b-row>
                    <!-- Overview images -->
                    <b-col md="6">
                      <b-form-group
                        label="Overview images"
                        label-for="product-add-region"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Overview images"
                          rules="required"
                        >
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="primary"
                            @click="
                              isShowModalSelectImageOverview =
                                !isShowModalSelectImageOverview
                            "
                          >
                            Select image
                          </b-button>
                          <div class="border rounded p-2 pb-0 mt-2">
                            <h6 v-if="imageOverview.length === 0">
                              No photos added yet
                            </h6>
                            <b-img
                              v-else
                              v-model="imageOverview"
                              center
                              fluid
                              :src="imageOverview[0].link"
                              height="100"
                            />
                          </div>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Header images -->
                    <b-col md="6">
                      <b-form-group
                        label="Header images"
                        label-for="product-add-region"
                        class="mb-2"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Header images"
                          rules="required"
                        >
                          <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            variant="primary"
                            @click="
                              isShowModalSelectImage = !isShowModalSelectImage
                            "
                          >
                            Select image
                          </b-button>
                          <div class="border rounded p-2 pb-0 mt-2">
                            <h6 v-if="imageHeader.length === 0">
                              No photos added yet
                            </h6>
                            <b-img
                              v-else
                              v-model="imageHeader"
                              center
                              fluid
                              :src="imageHeader[0].link"
                              height="100"
                            />
                          </div>

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-card-body>

            <b-col cols="12">
              <b-button
                v-if="!idProduct"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="confirmProduct"
              >
                Update event
              </b-button>
              <b-button
                v-if="idProduct"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click="confirmProduct"
              >
                Save
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                :to="{ name: 'event-manager' }"
                variant="outline-secondary"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </b-form>
        <!--/ form -->
      </validation-observer>
      <select-image-modal
        :is-show-modal-select-image.sync="isShowModalSelectImage"
        :selected-box="imageHeader"
        :region="productEdit.region_id"
        @set-image-id="setImageId"
      />
      <select-images-modal
        :is-show-modal-select-images.sync="isShowModalSelectImages"
        :selected-box="imageHeader"
        :region="productEdit.region_id"
        @set-images-id="setImagesId"
      />
      <select-image-modal
        :is-show-modal-select-image.sync="isShowModalImageSpeaker"
        :selected-box="imageHeader"
        :region="productEdit.region_id"
        @set-image-id="setImageSpeaker"
      />
      <select-image-modal
        :is-show-modal-select-image.sync="isShowModalSelectImageOverview"
        :selected-box="imageHeader"
        :region="productEdit.region_id"
        @set-image-id="setImageIdOverview"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import flatPickr from "vue-flatpickr-component";

import {
  BImg,
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BLink,
  BCardBody,
  BOverlay,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { quillEditor } from "vue-quill-editor";
// import { QuillEditor } from '@vueup/vue-quill'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { required } from "@validations";
// eslint-disable-next-line import/no-cycle
import store from "@/store";
// eslint-disable-next-line import/no-cycle
import router from "@/router";
import * as config from "@/utils/config";
// eslint-disable-next-line import/no-cycle
import moment from "moment";
import { ref } from "@vue/composition-api";
import SelectImageModal from "@/views/modal/SelectImageModal.vue";
import SelectImagesModal from "@/views/modal/SelectImagesModal.vue";
import * as utils from "@/utils/utils";
import editProduct from "./detailProduct";
import "swiper/swiper-bundle.css";

export default {
  components: {
    BImg,
    SelectImageModal,
    SelectImagesModal,
    Swiper,
    SwiperSlide,
    BCardBody,
    BLink,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    vSelect,
    quillEditor,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      active: "Active",
      inactive: "Inactive",
      snowOption: {
        placeholder: "",
        theme: "snow",
      },
      readOnlyOption: {
        placeholder: "Please choose region",
        theme: "snow",
      },
      required,
      dateNtim: null,
    };
  },
  computed: {
    ...mapGetters("app", ["regionOptions", "languageOptions"]),
    ...mapGetters("product", ["productCategory"]),
    ...mapGetters("product", ["productTags"]),
    ...mapGetters("product", ["productType"]),
    ...mapGetters("bussiness", ["productBussiess"]),
    ...mapGetters("role", ["productRole"]),
  },

  async created() {
    this.loading = true;
    await store.dispatch("product/fetchTags");
    this.loading = false;
  },
  setup() {
    const isShowModalSelectImage = ref(false);
    const isShowModalSelectImages = ref(false);
    const isShowModalImageSpeaker = ref(false);
    const isShowModalSelectImageOverview = ref(false);
    const currentIndexSpeaker = ref(null);
    const swiperOptions = {
      allowTouchMove: false,
      centeredSlides: true,
      slidesPerView: 4,
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };
    const {
      imageHeader,
      imagesHowItWork,
      imageOverview,
      productEdit,
      images,
      categogies,
      idProduct,
      fromPrice,
      toPrice,
      statusProduct,
      arrayDescription,
      count,
      updateItemForm,
      categorySelected,
      imagesId,
      loading,
    } = editProduct();
    return {
      swiperOptions,
      config,
      isShowModalSelectImage,
      isShowModalSelectImages,
      isShowModalImageSpeaker,
      isShowModalSelectImageOverview,
      currentIndexSpeaker,
      imageHeader,
      imagesHowItWork,
      imageOverview,
      productEdit,
      images,
      categogies,
      idProduct,
      fromPrice,
      toPrice,
      statusProduct,
      arrayDescription,
      count,
      updateItemForm,
      categorySelected,
      imagesId,
      loading,
    };
  },

  methods: {
    setImageIdOverview(arrayImage) {
      this.imageOverview = arrayImage;
    },
    async nextSlide() {
      await this.$refs.speakerRef.$swiper.slideNext();
    },
    async previousSlide() {
      await this.$refs.speakerRef.$swiper.slidePrev();
    },
    async removeElemenSpeaker(index) {
      await this.productEdit.speakers.splice(index, 1);
      await this.$refs.speakerRef.$swiper.slideTo(index - 1, 1);
    },
    async appendElemenSpeaker() {
      await this.productEdit.speakers.push({
        name: null,
        image: null,
        role: null,
      });
      await this.$refs.speakerRef.$swiper.slideTo(
        this.productEdit.speakers.length,
        1
      );
    },
    setImageSpeaker(imageArray) {
      this.productEdit.speakers[this.currentIndexSpeaker].image = imageArray[0];
      // this.imageHeader = arrayID
    },
    openSetImageSpeaker(index) {
      this.currentIndexSpeaker = index;
      this.isShowModalImageSpeaker = true;
    },
    onChangeTitle() {
      this.productEdit.slug = utils.toSlug(this.productEdit.title);
    },
    setImageId(arrayID) {
      this.imageHeader = arrayID;
    },
    setImagesId(arrayID) {
      this.imagesHowItWork = arrayID;
    },
    updateProductType(val) {
      this.productEdit.type = val;
    },
    addNewItemInItemFormDescription() {
      this.arrayDescription.push({
        title: "",
        description: "",
        region_id: "",
      });
      this.count += 1;
    },
    addNewItemInItemFormRegionPrice() {
      this.arrayDescription.push({
        title: "",
        description: "",
        region_id: "",
      });
      this.count += 1;
    },
    removeItem(index) {
      this.arrayDescription.splice(index, 1);
      this.count -= 1;
    },
    confirmProduct() {
      this.$refs.editProductValidation.validate().then((success) => {
        const arrImage = this.imageHeader.concat(this.imagesHowItWork);
        const arrImageID = arrImage.map((x) => x.id);
        if (success) {
          this.loading = true;
          const productUpdateData = {
            title: this.productEdit.title,
            tag_ids: this.productEdit.tag_ids.map((x) => x.value),
            product_role_ids: this.productEdit.product_roles.map(
              (x) => x.value
            ),
            product_bussiness_challenge_ids:
              this.productEdit.product_bussiness_challenges.map((x) => x.value),
            category_ids: this.productEdit.category_ids.map((x) => x.value),
            from_price: parseInt(this.productEdit.from_price, 10),
            slug: this.productEdit.slug,
            type: "low_touch",
            prices: [
              {
                from_price: 0,
                region_id: "string",
              },
            ],
            description: [
              {
                region_id: this.arrayDescription[0].region_id.region,
                title: this.arrayDescription[0].title,
                description: this.arrayDescription[0].description,
                summary: this.arrayDescription[0].summary,
              },
            ],
            media_ids: arrImageID,
            offering_type: "event",
            metadata: {
              speakers: this.productEdit.speakers,
              startDatetime: moment(this.productEdit.startDateTime)
                .utc()
                .valueOf(),
              duration: this.productEdit.duration,
              imageOverview: this.imageOverview[0],
              regions_form: this.arrayDescription,
            },
            metadata2: {
              startDatetime: moment(this.productEdit.startDateTime)
                .utc()
                .valueOf(),
              duration: this.productEdit.duration,
              properties: this.arrayDescription.map((x) => ({
                region_id: x.region_id,
                title: x.title,
                description: x.description,
                summary: x.summary,
              })),
            },
          };
          if (this.productEdit.status === "approved") {
            store
              .dispatch("product/updateProductOnEdit", {
                id: this.idProduct,
                product: productUpdateData,
              })
              .then((response) => {
                if (response.request.status === 201) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Update event successfully",
                      icon: "BellIcon",
                      variant: "success",
                    },
                  });
                  this.productEdit = null;
                  this.arrayDescription = [];
                  this.images = [];
                  this.imagesId = [];
                  this.loading = false;
                  router.push("/event-manager");
                }
              })
              .catch((e) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `${e.message}`,
                    icon: "BellIcon",
                    variant: "danger",
                  },
                });
              });
          } else {
            store
              .dispatch("product/updateProduct", {
                id: this.idProduct,
                product: productUpdateData,
              })
              .then((response) => {
                if (response.request.status === 200) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Update event successfully",
                      icon: "BellIcon",
                      variant: "success",
                    },
                  });
                  this.productEdit = null;
                  this.arrayDescription = [];
                  this.images = [];
                  this.imagesId = [];
                  this.loading = false;
                  router.push("/event-manager");
                }
              })
              .catch((e) => {
                this.loading = false;

                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `${e.message}`,
                    icon: "BellIcon",
                    variant: "danger",
                  },
                });
              });
          }
        }
      });
    },
    async uploadImageSuccess(formData) {
      await store
        .dispatch("media/updloadMedia", formData)
        .then((response) => {
          this.imagesId.push(response.body.id);
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.message}`,
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
    },
    beforeRemove(index, done) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete this image.", {
          title: "Please Confirm",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.imagesId.splice(index, 1);
            done();
          }
        });
    },
    async editImage(formData, index, fileList) {
      await store
        .dispatch("media/updloadMedia", formData)
        .then((response) => {
          this.imagesId[index] = response.body.id;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${error.response.data.message}`,
              icon: "BellIcon",
              variant: "danger",
            },
          });
        });
      this.images = [];
      fileList.forEach((e) => {
        if (!this.images.includes(e)) {
          this.images.push(e);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
@import "https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css";

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s ease-out;
}

.slide-fade-enter-from {
  transform: translateY(20px);
  opacity: 0;
}

.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}

.ql-container {
  min-height: inherit;
}
.swiper-button-next {
  color: #db536a;
}
.swiper-button-prev {
  color: #db536a;
}
.select-image {
  flex-direction: column;
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: center;
  border: dash grey;
}
.back-sub-element {
  justify-content: center;
  flex-direction: column;
  display: flex;
  border-top-right-radius: 1rem;
  border-end-end-radius: 1rem;
  background-color: #db536a;
}
.sub-element {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: xx-large;
  background-color: #db536a;
  color: white;
  border-radius: 1rem;
  border-color: #db536a;
  cursor: pointer;
  justify-content: center;
}
.add-element {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: xx-large;
  background-color: #db536a;
  color: white;
  border-radius: 1rem;
  border-color: #db536a;
  cursor: pointer;
  justify-content: center;
  padding: 2rem;
}
</style>
