import { ref } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import moment from 'moment'

export default function editProduct() {
  const vendorSelected = ref(null)
  const categorySelected = {}
  const idProduct = ref(null)
  const categogies = []
  const images = ref([])
  const imagesId = ref([])
  const imageHeader = ref([])
  const imagesHowItWork = ref([])
  const imageOverview = ref([])
  const arrayDescription = [
    {
      region_id: '',
      title: '',
      description: '',
    },
  ]
  const productEdit = ref({
    title: '',
    slug: '',
    tag_ids: [],
    category_id: {},
    type: {},
    form_price: '',
    speakers: [{ name: null, image: null, role: null }],
  })
  const fromPrice = ref(null)
  const toPrice = ref(null)
  const statusProduct = ref(null)
  const count = ref(null)
  const loading = ref(false)
  idProduct.value = router.currentRoute.params.id
  setTimeout(() => {
    loading.value = true
    store
      .dispatch('product/fetchProduct', { id: idProduct.value })
      .then(response => {
        productEdit.value = {
          region_id: response.body.region_id,
          title: response.body.title,
          tag_ids: response.body.tags.map(x => ({
            label: x.name,
            value: x.id,
          })),
          category_ids: response.body.categories.map(x => ({
            label: `[${x.region_id?.toUpperCase()}]-${x.name}`,
            value: x.id,
          })),
          product_roles: response.body.product_roles.map(x => ({
            label: `[${x.region_id?.toUpperCase()}]-${x.name}`,
            value: x.id,
          })),
          product_bussiness_challenges: response.body.product_bussiness_challenges.map(x => ({
            label: `[${x.region_id?.toUpperCase()}]-${x.name}`,
            value: x.id,
          })),
          status: response.body.status,
          from_price: response.body.price ? response.body.price.from_price : 0,
          slug: response.body.slug,
          speakers: response.body.metadata.metadata.speakers,
          duration: parseInt(response.body.metadata.metadata.duration, 10),
          startDateTime: moment(response.body.metadata.metadata.startDatetime).format('YYYY-MM-DD hh:mm'),
        }
        // Fetch enities by region
        if (productEdit.value.region_id) {
          const queryParams = {
            region_ids: [productEdit.value.region_id],
          }
          store.dispatch('bussiness/fetchBussiness', queryParams)
          store.dispatch('role/fetchRole', queryParams)
          const queryParamsCate = {
            region: productEdit.value.region_id,
          }
          store.dispatch('product/fetchCategories', queryParamsCate)
        }
        if (response.body.images.length > 0) {
          // productEdit.value.images = images.value = response.body.images.map(
          //   (x, i) => ({
          //     path: `${x.link}?sp=r&st=2022-12-06T16:32:46Z&se=2023-12-07T00:32:46Z&spr=https&sv=2021-06-08&sr=c&sig=izAnhRtos9EO61CURspF0vPvpis7vs4LNR9DhkNUHa8%3D`,
          //     default: i,
          //     highlight: i,
          //   }),
          // )
          // imagesId.value = response.body.images.map(x => x.id)
          for (let i = 0; i < response.body.images.length; i += 1) {
            if (response.body.images[i].type === 'header_image') {
              imageHeader.value.push(response.body.images[i])
            } else if (response.body.images[i].type === 'how_it_work') {
              imagesHowItWork.value.push(response.body.images[i])
            }
          }
        }
        arrayDescription.shift()
        count.value = response.body.metadata.metadata.regions_form.length
        response.body.metadata.metadata.regions_form.forEach(e => {
          arrayDescription.push(e)
        })
        console.log(arrayDescription)
        // Get image overview
        if (response.body.metadata.metadata.imageOverview) {
          imageOverview.value.push(
            response.body.metadata.metadata.imageOverview,
          )
        }
        loading.value = false
      })
      .catch(error => {
        loading.value = false
        console.log(error)
      })
  }, 0)

  const updateItemForm = (index, val) => {
    const region_id = val
    arrayDescription[index].region_id = region_id
  }
  return {
    imageHeader,
    imagesHowItWork,
    productEdit,
    images,
    categogies,
    idProduct,
    fromPrice,
    toPrice,
    statusProduct,
    arrayDescription,
    count,
    updateItemForm,
    vendorSelected,
    categorySelected,
    imagesId,
    loading,
    imageOverview,
  }
}
